import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"

const Kontakt = ({data}) => {
  return (
    <Layout title="Kontakt" slogan="Kontaktieren Sie uns" headerImage={data.image1.childImageSharp.fluid}>
      <p>Treten Sie mit uns bei offenen Fragen, Anregungen oder Ähnlichem in Kontakt. Wir werden Ihnen schnellstmöglich antworten. Ausserdem können Sie uns per Telefon, Fax oder Post ebenfalls erreichen:</p>
      <p style={{lineHeight: '3'}}>
         <b>Adresse:</b> Gaswerkstrasse 6, 8570 Weinfelden<br/>
         <b>E-Mail:</b> <a href="mailto:info@bannau.ch">info@bannau.ch</a><br/>
         <b>Telefon:</b> 071 622 14 11<br/>
         <b>Fax:</b> 071 622 19 10<br/>
      </p>
    </Layout>
  ) 
}

export default Kontakt

export const pageQuery = graphql`
  query {
    image1: file(sourceInstanceName: {eq: "assets"}, relativePath: { eq: "bannau-start-eingang.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 1600) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    },
  }
`